<template>
  <div>
    <v-img
      height="400px"
      :gradient="imageGradient"
      :src="image"
    >
      <v-card-text
  class="white--text display-2 font-weight-bold d-flex align-center justify-center fill-height">
        Encontre tudo para sua empresa
      </v-card-text>
    </v-img>
    <div>
      <v-container class="mkt-content-container mt-10">
        <v-row class="body-2 mb-3">
          <v-col cols="12">
            <router-link to="/home">
              Home
            </router-link>
            >
            <span>
            Para empresas
          </span>
          </v-col>
        </v-row>
        <v-row class="mt-10">
          <v-col cols="12" sm="6" class="pr-12">
            <span class="display-1 text--primary--color font-weight-bold">
              Para empresas
            </span>
            <p class="mt-12 text-justify">
              Oportunizamos que empresas façam suas compras com agilidade, simplicidade e segurança.
              Sem perder tempo com cotações ou processos de compra complexos.
              Tudo isso com preço baixo e um ambiente intuitivo.
            </p>
            <p class="text-justify">
              O Marketplace Proxpect oferece uma nova maneira de realizar as compras para sua
              empresa. Nosso compromisso é facilitar o dia-a-dia do comprador, proporcionando uma
              experiência de compras fácil, rápida e assertiva. Além disso, buscamos oferecer,
              por meio de nossos parceiros, os melhores produtos e preços do mercado. Isso é
              possível devido a grande quantidade de produtos e fornecedores, o que facilita que
              na redução de custo para o cliente.
            </p>
            <p>
              Confira abaixo os benefícios que você terá ao fazer parte do marketplace Proxpect:
            </p>
            <ul>
              <li>Nova experiência de compras;</li>
              <li>Agilidade e facilidade em compras (compras em apenas alguns cliques);</li>
              <li>Compra de produtos simples a complexos;</li>
              <li>Mais de 15.000 ofertas de produtos;</li>
              <li>Prospecção de fornecedores;</li>
              <li>Suporte para questões operacionais de frete, pagamento e cadastro de produtos;
              </li>
              <li>Comparação de preços e fornecedores e muito mais…</li>
            </ul>
            <p class="mt-5">
              Ficou interessado? Faça seu
              <router-link to="/criar-conta">
                cadastro aqui
              </router-link>
            </p>
          </v-col>
          <v-col cols="12" sm="6" v-if="!isMobile">
            <v-img class="rounded-card" :src="corpoImage"/>
          </v-col>
        </v-row>
        <px-vantagens class="mt-24" title="Vantagens em utilizar a Proxpect"/>
        <px-como-funciona class="mt-24"/>
      </v-container>
    </div>
  </div>
</template>

<script>
import gridMixin from '@/mixins/grid-mixin';
import Vantagens from '@/modules/institucional/Vantagens.vue';
import ComoFunciona from '@/modules/institucional/ComoFunciona.vue';
import capaImage from '../../assets/banner-area-comprador-institucional.png';
import corpoImage from '../../assets/comprador.png';

export default {
  mixins: [gridMixin],
  data() {
    return {
      imageGradient: this.$pxMarketplace.DEFAULT_IMAGE_GRADIENT,
      image: capaImage,
      corpoImage,
    };
  },
  components: {
    pxVantagens: Vantagens,
    pxComoFunciona: ComoFunciona,
  },
};
</script>
